import {Component} from '@angular/core';
import {faChevronLeft, faChevronRight} from '@fortawesome/fontawesome-free-solid';
import fontawesome from '@fortawesome/fontawesome';

// styleUrls: ['./app.component.scss']
@Component({
    selector: 'app-root',
    template: `
        <div id="tl">
            <router-outlet></router-outlet>
        </div>`,
    styleUrls: []
})
export class AppComponent {
    title = 'Bney Israel';

}
