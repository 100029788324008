<app-authentication #authenticationModal [modalConfig]="authenticationModalConfig"></app-authentication>

<!-- Intro -->
<section id="intro">
    <div class="overlay overlay-bg"></div>
    <div class="owl-carousel">
        <div class="item section-padding first-banner">
            <div class="container">
                <div class="intro_text white_text"><span class="banner-icon"><img
                    src="/static/src/static/images/israel.svg"
                    alt="icon"
                    class="img-fluid mx-auto d-block"></span>
                    <span class="banner-sub">Welcome To Jah</span>
                    <h1>Shabbat Shalom</h1>
                    <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as
                        opposed to using 'Content here, content here.</p>
                    <div class="row">
                        <div class="col"><a class="btn dark-btn tilak-top-btn" (click)="openAuthenticationModal()">Become a member</a>
                        </div>
                        <div class="col"><a class="btn dark-btn tilak-top-btn">Register community</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item section-padding second-banner">
            <div class="container">
                <div class="intro_text white_text"><span class="banner-icon"><img
                    src="/static/src/static/images/israel.svg"
                    alt="icon"
                    class="img-fluid mx-auto d-block"></span>
                    <span class="banner-sub">We Believe in God</span>
                    <h1>Chag Sameach</h1>
                    <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as
                        opposed to using 'Content here, content here.</p>
                    <div class="row">
                        <div class="col"><a  class="btn dark-btn tilak-top-btn" (click)="openAuthenticationModal()">Become a member</a>
                        </div>
                        <div class="col"><a class="btn dark-btn tilak-top-btn">Register community</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item section-padding third-banner">
            <div class="container">
                <div class="intro_text white_text"><span class="banner-icon"><img
                    src="/static/src/static/images/israel.svg"
                    alt="icon"
                    class="img-fluid mx-auto d-block"></span>
                    <span class="banner-sub">We Believe in God</span>
                    <h1>Chag Sameach</h1>
                    <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as
                        opposed to using 'Content here, content here.</p>
                    <div class="row">
                        <div class="col"><a  class="btn dark-btn tilak-top-btn" (click)="openAuthenticationModal()">Become a member</a>
                        </div>
                        <div class="col"><a class="btn dark-btn tilak-top-btn">Register community</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Intro -->
