<ng-template #modal>
    <div class="modal-header auth-modal-header">
        <button type="button" aria-label="Close" class="close" (click)="close()"
                *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
                [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()"><span
            aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body auth-modal">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem (click)="signInTabAnimate()" [ngClass]="{'selected' : tabSetAnimation === 0 }">
                <a class="title" ngbNavLink>Sign In</a>
                <ng-template ngbNavContent>
                    <app-signin [@tabsetAnimation]="tabSetAnimation"></app-signin>
                </ng-template>
            </li>
            <li ngbNavItem (click)="signUpTabAnimate()" [ngClass]="{'selected' : tabSetAnimation === 1 }">
                <a class="title" ngbNavLink>Sign Up</a>
                <ng-template ngbNavContent>
                    <app-signup [@tabsetAnimation]="tabSetAnimation"></app-signup>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</ng-template>
