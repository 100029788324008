import {Component, Injectable, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ILModalConfig} from '../../configs/model.config';
import {transition, trigger, style, animate, query, group} from '@angular/animations';

const slideLeft = [
    query(':enter, :leave', style({position: 'fixed', width: '100%'}), {optional: true}),
    group([
        query(':enter', [style({transform: 'translateX(-100%)'}), animate('.3s ease-out', style({transform: 'translateX(0%)'}))], {
            optional: true,
        }),
        query(':leave', [style({transform: 'translateX(0%)'}), animate('.3s ease-out', style({transform: 'translateX(100%)'}))], {
            optional: true,
        }),
    ]),
];

const slideRight = [
    query(':enter, :leave', style({position: 'fixed', width: '100%'}), {optional: true}),
    group([
        query(':enter', [style({transform: 'translateX(100%)'}), animate('.3s ease-out', style({transform: 'translateX(0%)'}))], {
            optional: true,
        }),
        query(':leave', [style({transform: 'translateX(0%)'}), animate('.3s ease-out', style({transform: 'translateX(-100%)'}))], {
            optional: true,
        }),
    ]),
];

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
    animations: [
        trigger('tabsetAnimation', [
            transition(':enter, * => 0, * => -1', []),
            transition(':increment', slideRight),
            transition(':decrement', slideLeft)
        ])
    ]
})
@Injectable()
export class ILAuthenticationComponent implements OnInit {
    @Input() public modalConfig: ILModalConfig;
    @ViewChild('modal') private modalContent: TemplateRef<ILAuthenticationComponent>;
    private modalRef: NgbModalRef;
    private tabSetAnimation = 0;

    constructor(private modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

    open(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.modalRef = this.modalService.open(this.modalContent, {centered: true});
            this.modalRef.result.then(resolve, resolve);
        });
    }

    async close(): Promise<void> {
        if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
            const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose());
            this.modalRef.close(result);
        }
    }

    async dismiss(): Promise<void> {
        if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
            const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss());
            this.modalRef.dismiss(result);
        }
    }

    signInTabAnimate() {
        if (this.tabSetAnimation > 0) {
            this.tabSetAnimation--;
        }
    }

    signUpTabAnimate() {
        this.tabSetAnimation++;
    }

    hide() {
        this.signUpTabAnimate();
    }
}
