<!-- Footer -->
<footer id="footer">
    <!-- Footer-Widgets -->
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 footer_widget">
                <div class="widget_inner">
                    <div class="footer_logo">
                        <a href="#">
                            <img src="https://via.placeholder.com/110x46" alt="image">
                        </a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et consequat elit. Proin molestie
                        eros sed urna auctor lobortis.Proin</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 footer_widget">
                <div class="widget_inner">
                    <h6>Timings</h6>
                    <div class="footer_nav">
                        <ul class="timings">
                            <li><a href="#">
                                Mon (9am – 5pm)</a>
                            </li>
                            <li><a href="#">
                                Tue (9am – 5pm)</a>
                            </li>
                            <li><a href="#">
                                Wed (9am – 5pm)</a>
                            </li>
                            <li><a href="#">
                                Thu (9am – 5pm)</a>
                            </li>
                            <li><a href="#">
                                Fri (9am – 5pm)</a>
                            </li>
                            <li><a href="#">
                                Sat (10am – 4pm)</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 footer_widget">
                <div class="widget_inner">
                    <h6>Useful Links</h6>
                    <div class="footer_nav">
                        <ul>
                            <li><a href="#">FAQ</a>
                            </li>
                            <li><a href="#">Privacy Policy</a>
                            </li>
                            <li><a href="#">Terms</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 footer_widget">
                <div class="widget_inner">
                    <h6>Quick Links</h6>
                    <div class="footer_nav">
                        <ul>
                            <li><a href="index.html">Home</a>
                            </li>
                            <li><a href="about-us.html">About Us</a>
                            </li>
                            <li><a href="contact-us.html">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Footer-Widgets -->
    <!-- Footer-Bottom -->
    <div class="footer_bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-6 align-self-center">
                    <p class="mb-0 copyright-text">&copy; 2020 Jewish All Rights Reserved</p>
                </div>
                <div class="col-md-6 align-self-center">
                    <div class="contact-social-icons">
                        <ul class="list-inline">
                            <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a>
                            </li>
                            <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a>
                            </li>
                            <li class="list-inline-item"><a href="#"><i class="fab fa-youtube"></i></a>
                            </li>
                            <li class="list-inline-item"><a href="#"><i class="fab fa-linkedin-in"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="back-top" class="back-top"><a href="#top"><i class="fa fa-angle-up" aria-hidden="true"></i>
                </a>
                </div>
            </div>
        </div>
    </div>
    <!-- /Footer-Bottom -->
</footer>

