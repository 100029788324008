import {Component, Injectable, OnInit} from '@angular/core';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class ILSigninComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
