import {Component, OnInit, ViewChild} from '@angular/core';
import {ILModalConfig} from '../../configs/model.config';
import {ILAuthenticationComponent} from '../authentication/authentication.component';

@Component({
    selector: 'app-intro',
    templateUrl: './intro.component.html',
    styleUrls: ['./intro.component.scss']
})
export class ILIntroComponent implements OnInit {
    @ViewChild('authenticationModal') private authenticationModalComponent: ILAuthenticationComponent;
    authenticationModalConfig: ILModalConfig;

    constructor() {
        this.authenticationModalConfig = {} as ILModalConfig;
    }

    ngOnInit(): void {
    }

    async openAuthenticationModal(): Promise<any> {
        return await this.authenticationModalComponent.open();
    }
}
