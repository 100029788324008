import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ILLandingComponent} from './components/landing/landing.component';

const routes: Routes = [
    {path: 'landing', component: ILLandingComponent},
    {path: '', redirectTo: 'landing', pathMatch: 'full'},
    {path: '**', redirectTo: 'landing', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
