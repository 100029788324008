<!-- Header -->
<header id="header" class="nav-stacked" data-spy="affix" data-offset-top="1">
    <!-- Navigation -->
    <nav id="navigation_bar" class="navbar navbar-default">
        <div class="container">
            <div class="navbar-header">
                <div class="logo">
                    <a href="index.html">
                        <img src="https://via.placeholder.com/110x46" alt="image"/>
                    </a>
                </div>
                <!-- /Logo -->
                <div id="menu_slide">
                    <div id="nav-toggle-label">
                        <div id="hamburger"><span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div id="cross"><span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="collapse navbar-collapse" id="navigation">
                <ul class="nav navbar-nav">
                    <li class="dropdown"><a href="#">Home<span class="nav_arrow"></span></a>
                        <ul class="sub-menu">
                            <li><a href="index.html">Homepage 1</a>
                            </li>
                            <li><a href="index2.html">Homepage 2</a>
                            </li>
                        </ul>
                    </li>
                    <li><a href="about-us.html">About Us</a>
                    </li>
                    <li class="dropdown"><a href="events.html">Event <span class="nav_arrow"></span></a>
                        <ul class="sub-menu">
                            <li><a href="events.html">Event</a>
                            </li>
                            <li><a href="event-detail.html">Event Detail</a>
                            </li>
                        </ul>
                    </li>
                    <li class="dropdown"><a href="#">Pages <span class="nav_arrow"></span></a>
                        <ul class="sub-menu">
                            <li><a href="our-teachers.html">our-teachers</a>
                            </li>
                            <li><a href="shop-full-width.html">shop-full-width</a>
                            </li>
                            <li><a href="shop-left.html">shop-left</a>
                            </li>
                            <li><a href="shop-right.html">shop-right</a>
                            </li>
                            <li><a href="shop-details.html">shop-details</a>
                            </li>
                            <li><a href="log-in.html">log-in</a>
                            </li>
                            <li><a href="sign-up.html">sign-up</a>
                            </li>
                        </ul>
                    </li>
                    <li class="dropdown"><a href="#">Blog <span class="nav_arrow"></span></a>
                        <ul class="sub-menu">
                            <li><a href="blog.html">Blog</a>
                            </li>
                            <li><a href="blog-detail.html">Blog Detail</a>
                            </li>
                        </ul>
                    </li>
                    <li><a href="shop-full-width.html">shop</a>
                    </li>
                    <li><a href="contact-us.html">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Navigation end -->
    <!-- Mobile Navigation -->
    <div class="mobile-menu">
        <ul class="wd-menu pop-scroll">
            <li class="has-child"><a href="#">Home</a>
                <ul class="sub-menu">
                    <li><a href="index.html">Homepage 1</a>
                    </li>
                    <li><a href="index2.html">Homepage 2</a>
                    </li>
                </ul>
            </li>
            <li class="has-child"><a href="#">Blog</a>
                <ul class="sub-menu">
                    <li><a href="blog.html">Blog</a>
                    </li>
                    <li><a href="blog-detail.html">Blog Detail</a>
                    </li>
                </ul>
            </li>
            <li class="has-child"><a href="#">Pages</a>
                <ul class="sub-menu">
                    <li><a href="our-teachers.html">our-teachers</a>
                    </li>
                    <li><a href="shop-left.html">shop-left</a>
                    </li>
                    <li><a href="shop-right.html">shop-right</a>
                    </li>
                    <li><a href="shop-details.html">shop-details</a>
                    </li>
                    <li><a href="shop-full-width.html">shop-full-width</a>
                    </li>
                    <li><a href="log-in.html">log-in</a>
                    </li>
                    <li><a href="sign-up.html">sign-up</a>
                    </li>
                </ul>
            </li>
            <li><a href="about-us.html">About us</a>
            </li>
            <li><a href="contact-us.html">contact us</a>
            </li>
            <li class="has-child"><a href="#">Event</a>
                <ul class="sub-menu">
                    <li><a href="events.html">Event</a>
                    </li>
                    <li><a href="event-detail.html">Event Detail</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    <!-- END/Mobile Navigation -->
</header>
<!-- /Header -->
