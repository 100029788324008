import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class ILLandingComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }


}
