import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ILLandingComponent} from './components/landing/landing.component';
import {ILHeaderComponent} from './components/header/header.component';
import {ILFooterComponent} from './components/footer/footer.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ILIntroComponent} from './components/intro/intro.component';
import {ILAboutComponent} from './components/about/about.component';
import {ILEventsComponent} from './components/events/events.component';
import {APP_BASE_HREF, PlatformLocation} from '@angular/common';
import {ILSigninComponent} from './components/signin/signin.component';
import {ILSignupComponent} from './components/signup/signup.component';
import {ILAuthenticationComponent} from './components/authentication/authentication.component';

@NgModule({
    declarations: [
        AppComponent,
        ILLandingComponent,
        ILHeaderComponent,
        ILFooterComponent,
        ILIntroComponent,
        ILAboutComponent,
        ILEventsComponent,
        ILSigninComponent,
        ILSignupComponent,
        ILAuthenticationComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbModule,
        FontAwesomeModule
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
            deps: [PlatformLocation]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
