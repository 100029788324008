<div class="row no-gutter">
    <div class="col-md-12">
        <div class="login py-5">
            <div class="row">
                <div class="col-md-12 offset-col-12 mx-auto d-block login-page">
                    <form class="login-form-t">
                        <div class="form-label-group">
                            <label for="inputEmail">Email address</label>
                            <input type="email" id="inputEmail" class="form-control"
                                   placeholder="Email address" required="">

                        </div>

                        <div class="form-label-group">
                            <label for="inputPassword">Password</label>
                            <input type="password" id="inputPassword" class="form-control"
                                   placeholder="Password" required="">
                        </div>

                        <div class="custom-control custom-checkbox mb-3">
                            <input type="checkbox" class="custom-control-input"
                                   id="customCheck1">
                            <label class="custom-control-label" for="customCheck1">Remember
                                password</label>
                        </div>
                        <button
                            class="btn btn-lg btn-block tl-btn-round-2 text-uppercase font-weight-bold mb-2"
                            type="submit">Sign in
                        </button>

                        <div class="text-center">
                            <a class="small" href="#">Forgot password?</a>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

