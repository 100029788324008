<app-header></app-header>
<app-intro></app-intro>
<app-about></app-about>
<!-- Causes -->
<section id="causes" class="section-padding gray_bg">
    <div class="container">
        <div class="owl-carousel">
            <div class="item">
                <div class="causes_info">
                    <p class="subtitle text-white">King Solomon, Kohelet</p>
                    <h4 class="white_text mb-0">"A performs action by body, mind, intellect, and senses, without
                        attachment (or ego),
                        only for self-purification."</h4>
                </div>
            </div>
            <div class="item">
                <div class="causes_info">
                    <p class="subtitle text-white">King Solomon, Kohelet</p>
                    <h4 class="white_text mb-0">"A performs action by body, mind, intellect, and senses, without
                        attachment (or ego),
                        only for self-purification."</h4>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Causes -->
<app-events></app-events>
<!-- Donation-img section -->
<section class="section-padding secondary-bg donation-img-section">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 col-lg-7 col-md-7 align-self-center">
                <div class="section-header">
                    <h3>Our Mission</h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some</p>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type scrambled it to make a type
                    specimen book. It has survived not only five.</p>
                <div class="donation_form">
                    <form>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"
                                                                               id="basic-addon1">$</span>
                                        </div>
                                        <input type="text" class="form-control w-auto mb-0" value="$100">
                                    </div>
                                    <ul class="select_amount">
                                        <li class="mb-0">$10.00</li>
                                        <li class="mb-0">$25.00</li>
                                        <li class="mb-0">$50.00</li>
                                        <li class="mb-0 active">$100.00</li>
                                        <li class="mb-0">$500.00</li>
                                    </ul>
                                    <input type="submit" class="btn dark-btn" value="Donate Now">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-xl-4 col-lg-5 col-md-5 align-self-center">
                <img src="/static/src/static/images/home-page/img-2.jpg" alt="img" class="img-fluid mx-auto d-block height-100">
            </div>
        </div>
    </div>
</section>
<!-- /Donation-img section -->
<!-- Our History -->
<section class="our_testimonials section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-5 align-self-center">
                <img src="/static/src/static/images/home-page/our-history.png" alt="" class="img-fluid mx-auto d-block height-100 Mb_25">
            </div>
            <div class="col-xl-8 col-lg-7 col-md-7 align-self-center">
                <div class="about_company">
                    <div class="section-header">
                        <h3>Our History</h3>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in some</p>
                    </div>
                    <ul class="nav nav-tabs testi-nav-tabs">
                        <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#home">1980</a>
                        </li>
                        <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu1">1990</a>
                        </li>
                        <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu2">2000</a>
                        </li>
                        <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu3">2010</a>
                        </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div id="home" class="container tab-pane active pl-0 pr-0">
                            <br>
                            <h5>1980</h5>
                            <p>Aliquam nec sem <strong class="text-custom-secondary">vulputate, sagittis</strong> felis
                                id, semper nibh.
                                Lorem ipsum dolor sit amet, consectetuer adipelit, sed diam nonummy nibh euismod
                                tincidunt ut
                                laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim
                                veniam, quis nostrud exerci tation ullamcorper suscipit lobortis <a href="about-us.html"
                                                                                                    class="text-link">Read
                                    More..</a></p>
                        </div>
                        <div id="menu1" class="container tab-pane fade pl-0 pr-0">
                            <br>
                            <h5>1990</h5>
                            <p>Integer tincidunt. Cras dapibus. <strong class="text-custom-secondary">vulputate,
                                sagittis</strong> nisi.
                                Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae,
                                eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
                                Phasellus viverra nulla ut metus varius laoreet. Nam ornare pellentesque tortor<a
                                    href="about-us.html" class="text-link">Read More..</a></p>
                        </div>
                        <div id="menu2" class="container tab-pane fade pl-0 pr-0">
                            <br>
                            <h5>2000</h5>
                            <p>Aliquam nec sem vulputate, sagittis felis id, semper nibh. <strong
                                class="text-custom-secondary">vulputate,
                                sagittis</strong>, consectetuer adipelit, sed diam nonummy nibh euismod
                                tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim
                                veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip, <a
                                    href="about-us.html" class="text-link">Read More..</a></p>
                        </div>
                        <div id="menu3" class="container tab-pane fade pl-0 pr-0">
                            <br>
                            <h5>2010</h5>
                            <p>Aliquam nec sem <strong class="text-custom-secondary">vulputate, sagittis</strong> felis
                                id, semper nibh.
                                Lorem ipsum dolor sit amet, consectetuer adip elit, sed diam nonummy nibh euismod
                                tincidunt ut laoreet dolore magna aliqerat volutpat. Ut wisi enim ad minim
                                veniam, quis nostrud exerci tation ullamcorper suscipit lobortis <a href="about-us.html"
                                                                                                    class="text-link">Read
                                    More..</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Our History -->
<!-- Call to Action -->
<section class="section-padding call-action-section pb-0">
    <div class="container">
        <div class="jah-countdown-intro">
            <p class="jah-countdown-date">25 June 2020</p>
            <h3 class="jah-countdown-event-name">Passover High Holiday</h3>
            <p class="jah-countdown-description">You need to be sure there isn't anything embarrassing hidden in the
                middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined</p>
            <div class="event-symbole">
                <img src="/static/src/static/images/hanukkah.svg" alt="event" class="img-fluid mx-auto d-block event-time-img">
            </div>
        </div>
    </div>
    <div class="jah-count-bg">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-12 align-self-center">
                    <div class="call-content mx-auto event-content">
                        <p class="subtitle text-white">Register Today</p>
                        <h5 class="text-white">Join us for a grand event</h5>
                        <p class="text-white">You need to be sure there isn't anything embarrassing hidden in the middle
                            of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined</p>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12 align-self-center">
                    <p class="subtitle text-white">Registration will end in</p>
                    <div class="timer event-timer">
                        <div id="countdown-intro"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Call to Action -->
<!-- START Testimonials -->
<section class="section-padding testimonal-section">
    <div class="sa-living-section">
        <div class="container">
            <div class="section-header-center text-center">
                <h3>Our Members</h3>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    alteration in some</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-10 col-12">
                    <div class="ss-synced-slider">
                        <div class="ss-slider-sync-nav" data-items="6">
                            <img src="/static/src/static/images/members/mem1.png" alt="img">
                            <img src="/static/src/static/images/members/mem2.png" alt="img">
                            <img src="/static/src/static/images/members/mem3.png" alt="img">
                            <img src="/static/src/static/images/members/mem4.png" alt="img">
                            <img src="/static/src/static/images/members/mem5.png" alt="img">
                            <img src="/static/src/static/images/members/mem1.png" alt="img">
                            <img src="/static/src/static/images/members/mem2.png" alt="img">
                            <img src="/static/src/static/images/members/mem3.png" alt="img">
                        </div>
                        <div class="sa-living-details text-center ss-slider-sync-content">
                            <div class="item">
                                <h5>Living A Life</h5>
                                <p>You need to be sure there isn't anything embar hidden in the middle of. All the Lorem
                                    Ipsum generators on the Internet tend to repeat predefined You need to be sure there
                                    isn't anything embarrassing hidden ithe middle
                                    of text. All the Lorem Ipsum generators on the Internet tend to repeat You need to
                                    be sure there anything embar.</p>
                                <span>Anne Mart</span>
                            </div>
                            <div class="item">
                                <h5>Living A Christian Life</h5>
                                <p>You need to be sure there isn't anything embar hidden in the middle of. All the Lorem
                                    Ipsum generators on the Internet tend to repeat predefined You need to be sure there
                                    isn't anything embarrassing hidden ithe middle
                                    of text. All the Lorem Ipsum generators on the Internet tend to repeat You need to
                                    be sure there anything embar.</p>
                                <span>Anne Mart</span>
                            </div>
                            <div class="item">
                                <h5>Living A Happy Life</h5>
                                <p>You need to be sure there isn't anything embar hidden in the middle of. All the Lorem
                                    Ipsum generators on the Internet tend to repeat predefined You need to be sure there
                                    isn't anything embarrassing hidden ithe middle
                                    of text. All the Lorem Ipsum generators on the Internet tend to repeat You need to
                                    be sure there anything embar.</p>
                                <span>Josh Trim</span>
                            </div>
                            <div class="item">
                                <h5>Living A Normal Life</h5>
                                <p>You need to be sure there isn't anything embar hidden in the middle of. All the Lorem
                                    Ipsum generators on the Internet tend to repeat predefined You need to be sure there
                                    isn't anything embarrassing hidden ithe middle
                                    of text. All the Lorem Ipsum generators on the Internet tend to repeat You need to
                                    be sure there anything embar.</p>
                                <span>Aviram</span>
                            </div>
                            <div class="item">
                                <h5>Living A Christian Life</h5>
                                <p>You need to be sure there isn't anything embar hidden in the middle of. All the Lorem
                                    Ipsum generators on the Internet tend to repeat predefined You need to be sure there
                                    isn't anything embarrassing hidden ithe middle
                                    of text. All the Lorem Ipsum generators on the Internet tend to repeat You need to
                                    be sure there anything embar.</p>
                                <span>Michle Angelo</span>
                            </div>
                            <div class="item">
                                <h5>Living A Christian Life</h5>
                                <p>You need to be sure there isn't anything embar hidden in the middle of. All the Lorem
                                    Ipsum generators on the Internet tend to repeat predefined You need to be sure there
                                    isn't anything embarrassing hidden ithe middle
                                    of text. All the Lorem Ipsum generators on the Internet tend to repeat You need to
                                    be sure there anything embar.</p>
                                <span>Gayle</span>
                            </div>
                            <div class="item">
                                <h5>Living A Life</h5>
                                <p>You need to be sure there isn't anything embar hidden in the middle of . All the
                                    Lorem Ipsum generators on the Internet tend to repeat predefined You need to be sure
                                    there isn't anything embarrassing hidden ithe middle
                                    of text. All the Lorem Ipsum generators on the Internet tend to repeat You need to
                                    be sure there anything embar.</p>
                                <span>Lilly Toller</span>
                            </div>
                            <div class="item">
                                <h5>Living A Christian Life</h5>
                                <p>You need to be sure there isn't anything embar hidden in the middle of. All the Lorem
                                    Ipsum generators on the Internet tend to repeat predefined You need to be sure there
                                    isn't anything embarrassing hidden ithe middle
                                    of text. All the Lorem Ipsum generators on the Internet tend to repeat You need to
                                    be sure there anything embar.</p>
                                <span>Kim Tylor</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END Testimonials -->
<!-- Latest-Blog -->
<section class="latest_blog section-padding secondary-bg" id="blog">
    <div class="container">
        <div class="blog">
            <div class="section-header-center text-center">
                <h3>Our Blog</h3>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                    alteration in some</p>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="owl-carousel blog-slider">
                        <div class="item">
                            <article class="blog-section">
                                <div class="blog_wrap position-relative">
                                    <div class="blog-post-date"><strong>03</strong>Dec</div>
                                    <div class="blog_img ">
                                        <a href="blog-detail.html">
                                            <img src="/static/src/static/images/home-page/news-01.png" alt="image">
                                        </a>
                                    </div>
                                    <div class="blog_info">
                                        <div class="post_meta"><span><a href="#">Meditation</a></span>
                                        </div>
                                        <h5><a href="blog-detail.html">Сommunity that makes Judaism relevant</a></h5>
                                        <p>You need to be sure there isn't anything embarrassing hidden in the middle
                                            of.
                                            All the Lorem Ipsum generators on the Internet tend to repeat predefined</p>
                                        <a href="blog-detail.html" class="btn">Read More <i
                                            class="fa fa-caret-right"></i> </a>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="item">
                            <article class="blog-section">
                                <div class="blog_wrap position-relative">
                                    <div class="blog-post-date"><strong>13</strong>Jan</div>
                                    <div class="blog_img">
                                        <a href="blog-detail.html">
                                            <img src="/static/src/static/images/home-page/news-02.png" alt="image">
                                        </a>
                                    </div>
                                    <div class="blog_info">
                                        <div class="post_meta"><span><a href="#">Yoga</a></span>
                                            <span><a href="#">Books</a></span>
                                        </div>
                                        <h5><a href="blog-detail.html">Learn more information about our synagogue</a>
                                        </h5>
                                        <p>You need to be sure there isn't anything embarrassing hidden in the middle
                                            of.
                                            All the Lorem Ipsum generators on the Internet tend to repeat predefined</p>
                                        <a href="blog-detail.html" class="btn">Read More <i
                                            class="fa fa-caret-right"></i> </a>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="item">
                            <article class="blog-section">
                                <div class="blog_wrap position-relative">
                                    <div class="blog-post-date"><strong>31</strong>Dec</div>
                                    <div class="blog_img">
                                        <a href="blog-detail.html">
                                            <img src="/static/src/static/images/home-banner/banner-01.png" alt="image">
                                        </a>
                                    </div>
                                    <div class="blog_info">
                                        <div class="post_meta"><span><a href="#">Meditation</a></span>
                                            <span><a href="#">Peace</a></span>
                                        </div>
                                        <h5><a href="blog-detail.html">Your generous support helps our community</a>
                                        </h5>
                                        <p>You need to be sure there isn't anything embarrassing hidden in the middle
                                            of.
                                            All the Lorem Ipsum generators on the Internet tend to repeat predefined</p>
                                        <a href="blog-detail.html" class="btn">Read More <i
                                            class="fa fa-caret-right"></i> </a>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="item">
                            <article class="blog-section">
                                <div class="blog_wrap position-relative">
                                    <div class="blog-post-date"><strong>04</strong>Nov</div>
                                    <div class="blog_img">
                                        <a href="blog-detail.html">
                                            <img src="/static/src/static/images/home-banner/banner-02.png" alt="image">
                                        </a>
                                    </div>
                                    <div class="blog_info">
                                        <div class="post_meta">
                                            <span><a href="#">Enlightment</a></span>
                                            <span><a href="#">Peace</a></span>
                                        </div>
                                        <h5><a href="blog-detail.html">Learn more information about our synagogue</a>
                                        </h5>
                                        <p>You need to be sure there isn't anything embarrassing hidden in the middle
                                            of.
                                            All the Lorem Ipsum generators on the Internet tend to repeat predefined</p>
                                        <a href="blog-detail.html" class="btn">Read More <i
                                            class="fa fa-caret-right"></i> </a>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="item">
                            <article class="blog-section">
                                <div class="blog_wrap position-relative">
                                    <div class="blog-post-date"><strong>13</strong>Nov</div>
                                    <div class="blog_img">
                                        <a href="blog-detail.html">
                                            <img src="/static/src/static/images/home-page/news-01.png" alt="image">
                                        </a>
                                    </div>
                                    <div class="blog_info">
                                        <div class="post_meta"><span><a href="#">Meditation</a></span>
                                            <span><a href="#">Books</a></span>
                                        </div>
                                        <h5><a href="blog-detail.html">Сommunity that makes Judaism relevant</a></h5>
                                        <p>You need to be sure there isn't anything embarrassing hidden in the middle
                                            of.
                                            All the Lorem Ipsum generators on the Internet tend to repeat predefined</p>
                                        <a href="blog-detail.html" class="btn">Read More <i
                                            class="fa fa-caret-right"></i> </a>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="item">
                            <article class="blog-section">
                                <div class="blog_wrap position-relative">
                                    <div class="blog-post-date"><strong>20</strong>Dec</div>
                                    <div class="blog_img">
                                        <a href="blog-detail.html">
                                            <img src="/static/src/static/images/home-page/news-02.png" alt="image">
                                        </a>
                                    </div>
                                    <div class="blog_info">
                                        <div class="post_meta"><span><a href="#">Buddha</a></span>
                                            <span><a href="#">Enlightment</a></span>
                                        </div>
                                        <h5><a href="blog-detail.html">Learn more information about our synagogue</a>
                                        </h5>
                                        <p>You need to be sure there isn't anything embarrassing hidden in the middle
                                            of.
                                            All the Lorem Ipsum generators on the Internet tend to repeat predefined</p>
                                        <a href="blog-detail.html" class="btn">Read More <i
                                            class="fa fa-caret-right"></i> </a>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END Latest-Blog -->

<section class="section-padding pb-0">
    <div class="container">
        <div class="section-header-center text-center">
            <h3>Follow Us</h3>
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
                in some</p>
        </div>
    </div>
    <ul class="hm-list hm-instagram">
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-01.png" alt="instagram">
            </a>
        </li>
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-02.png" alt="instagram">
            </a>
        </li>
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-03.png" alt="instagram">
            </a>
        </li>
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-04.png" alt="instagram">
            </a>
        </li>
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-05.png" alt="instagram">
            </a>
        </li>
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-06.png" alt="instagram">
            </a>
        </li>
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-07.png" alt="instagram">
            </a>
        </li>
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-08.png" alt="instagram">
            </a>
        </li>
        <li>
            <a href="#">
                <img src="/static/src/static/images/home-page/insta-09.png" alt="instagram">
            </a>
        </li>
    </ul>
</section>
<app-footer></app-footer>
