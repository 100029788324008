<!-- Latest-Events-Sermons -->
<section class="section-padding latest_event_sermons m-0">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-6">
                <div class="heading">
                    <h3>Our Events</h3>
                    <div class="tl-slider-arrow float-right">
                        <div class="tl-left-arrow slick-arrow mr-2" style=""><span><i
                            class="fa fa-chevron-up"></i></span>
                        </div>
                        <div class="tl-right-arrow slick-arrow" style=""><span><i class="fa fa-chevron-down"></i></span>
                        </div>
                    </div>
                </div>
                <div class="event_list event-slider slick-vertical">
                    <div class="event-slider-item">
                        <div class="row">
                            <div class="col-12">
                                <div class="event-list">
                                    <ul>
                                        <li>
                                            <div class="event_info">
                                                <div class="event_date"><span>02</span> sep'19</div>
                                                <h6><a href="event-detail.html">Purim Schpiel</a></h6>
                                                <ul>
                                                    <li><i class="far fa-clock"></i> Sunday (8:00 am -9:00 am)</li>
                                                    <li><i class="fas fa-map-marker-alt"></i>4873 Pretty View Lane New
                                                        York
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="event_info">
                                                <div class="event_date"><span>16</span> sep'19</div>
                                                <h6><a href="event-detail.html">Reading Megillat</a></h6>
                                                <ul>
                                                    <li><i class="far fa-clock"></i> tuesday (9:00 am -8:00 pm)</li>
                                                    <li><i class="fas fa-map-marker-alt"></i>4873 Pretty View Lane New
                                                        York
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="event_info">
                                                <div class="event_date"><span>12</span> oct'19</div>
                                                <h6><a href="event-detail.html">Passover High Holiday </a></h6>
                                                <ul>
                                                    <li><i class="far fa-clock"></i> Sunday (8:00 pm -9:00 pm)</li>
                                                    <li><i class="fas fa-map-marker-alt"></i>4873 Pretty View Lane New
                                                        York
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-slider-item">
                        <div class="row">
                            <div class="col-12">
                                <div class="event-list">
                                    <ul>
                                        <li>
                                            <div class="event_info">
                                                <div class="event_date"><span>02</span> sep'19</div>
                                                <h6><a href="event-detail.html">Traditional concert</a></h6>
                                                <ul>
                                                    <li><i class="far fa-clock"></i> Sunday (8:00 am -9:00 am)</li>
                                                    <li><i class="fas fa-map-marker-alt"></i>4873 Pretty View Lane New
                                                        York
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="event_info">
                                                <div class="event_date"><span>16</span> sep'19</div>
                                                <h6><a href="event-detail.html">Passover High Holiday</a></h6>
                                                <ul>
                                                    <li><i class="far fa-clock"></i> tuesday (9:00 am -8:00 pm)</li>
                                                    <li><i class="fas fa-map-marker-alt"></i>4873 Pretty View Lane New
                                                        York
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="event_info">
                                                <div class="event_date"><span>12</span> oct'19</div>
                                                <h6><a href="event-detail.html">Purim Schpiel</a></h6>
                                                <ul>
                                                    <li><i class="far fa-clock"></i> Sunday (8:00 pm -9:00 pm)</li>
                                                    <li><i class="fas fa-map-marker-alt"></i>4873 Pretty View Lane New
                                                        York
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="heading">
                    <h3>Our Meetups</h3>
                    <a href="events.html" class="btn btn-sm float-right">See All</a>
                </div>
                <div class="box_wrap video-bg home-video">
                    <div class="video-content">
                        <div class="video_icon">
                            <a class="popup-youtube" href="https://www.youtube.com/watch?v=jKGdZczmBcE"> <i
                                class="fas fa-play"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="video-heading">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lor Ipsum has been the industry's,
                        when an unknown printer took a and scrambled it to make a type
                        specimen book.</p>
                    <ul>
                        <li><i class="far fa-clock"></i> Sunday (8:00 am -9:00 am)</li>
                        <li><i class="fas fa-map-marker-alt"></i>4873 Pretty View Lane New York</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Latest-Events-Sermons -->
