<!-- About -->
<section class="about_intro section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-12 align-self-center">
                <div class="about-us-content">
                    <div class="section-header">
                        <h2>Community that makes Judaism <u class="text-custom-primary">meaningful</u></h2>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                            suffered alteration in some</p>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting.</p>
                </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12 align-self-center">
                <div class="about-us-image">
                    <img src="/static/src/static/images/lotus.png" alt="img"
                         class="img-fluid mx-auto d-block height-100 mt-md-30">
                </div>
            </div>
        </div>
        <!-- Features -->
        <div class="features">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="features_wrap features-after-none feature-line-one custom-mb">
                        <div class="f-f-icon">
                            <img src="/static/src/static/images/peace.png" alt="img">
                        </div>
                        <h5 class="text-custom-secondary">Synagogue</h5>
                        <p class="mb-0">Uncover many web sites still in their infancy. Various versions have evolved
                            over the years, sometimes by accident.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="features_wrap features-after-none feature-line-one custom-mb">
                        <div class="f-f-icon">
                            <img src="/static/src/static/images/team.png" alt="img">
                        </div>
                        <h5 class="text-custom-secondary">Community</h5>
                        <p class="mb-0">Uncover many web sites still in their infancy. Various versions have evolved
                            over the years, sometimes by accident.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="features_wrap features-after-none feature-line-one custom-mb">
                        <div class="f-f-icon">
                            <img src="/static/src/static/images/open-book.png" alt="img">
                        </div>
                        <h5 class="text-custom-secondary">Judaism</h5>
                        <p class="mb-0">Uncover many web sites still in their infancy. Various versions have evolved
                            over the years, sometimes by accident.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="features_wrap features-after-none custom-sm-mb">
                        <div class="f-f-icon">
                            <img src="/static/src/static/images/peace1.png" alt="img">
                        </div>
                        <h5 class="text-custom-secondary">Education</h5>
                        <p class="mb-0">Uncover many web sites still in their infancy. Various versions have evolved
                            over the years, sometimes by accident.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="features_wrap features-after-none custom-sm-mb">
                        <div class="f-f-icon">
                            <img src="/static/src/static/images/peace2.png" alt="img">
                        </div>
                        <h5 class="text-custom-secondary">Yiddish</h5>
                        <p class="mb-0">Uncover many web sites still in their infancy. Various versions have evolved
                            over the years, sometimes by accident.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="features_wrap features-after-none">
                        <div class="f-f-icon">
                            <img src="/static/src/static/images/peace3.png" alt="img">
                        </div>
                        <h5 class="text-custom-secondary">Philosophy</h5>
                        <p class="mb-0">Uncover many web sites still in their infancy. Various versions have evolved
                            over the years, sometimes by accident.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Features -->
    </div>
</section>
<!-- /About -->
